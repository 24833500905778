import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
// import axios from 'axios' //引入axios

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			adsensConfig: {
				scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3125188477567991',
				ad1: {
					"style": "display:block",
					"data-ad-client": "ca-pub-3125188477567991",
					"data-ad-slot": "5312417958",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				  },
				  ad2: {
					"style": "display:block",
					"data-ad-client": "ca-pub-3125188477567991",
					"data-ad-slot": "5022480206",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				  },
				  ad3: {
					"style": "display:block",
					"data-ad-client": "ca-pub-3125188477567991",
					"data-ad-slot": "3999336287",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				  },
				  ad4: {
					"style": "display:block",
					"data-ad-client": "ca-pub-3125188477567991",
					"data-ad-slot": "6922719383",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				  },
				  ad5: {
					"style": "display:block",
					"data-ad-client": "ca-pub-3125188477567991",
					"data-ad-slot": "5120846262",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				  },
				  ad6: {
					"style": "display:block",
					"data-ad-client": "ca-pub-3125188477567991",
					"data-ad-slot": "2396316865",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				  },
		
				  
			},
			collocationLoading: false,
			host: '',
			deviceType: 'mobile',
			firebaseConfig: {
				apiKey: "AIzaSyBcS3cwlUXpK99s0FiNLcdhiTqTbqa8pRo",
				authDomain: "webs-58a8d.firebaseapp.com",
				projectId: "webs-58a8d",
				storageBucket: "webs-58a8d.appspot.com",
				messagingSenderId: "730684174767",
				appId: "1:730684174767:web:d217e50ee0910a600c3f5a",
				measurementId: "G-MXC8FPJZ3B"
			  },
			  showDebug: false,
		},
		actions: {
			set_loading({ commit }, boolean) {
				commit('setLoading', boolean)
			},
			fetchPage2Data({ commit }, { page }) {
				console.log('收到dispatch,准备模拟异步请求')
				// 模拟异步数据请求，替换为真实的 API 请求
				console.log(page)
				return new Promise((resolve) => {
					setTimeout(() => {
						const data = Array.from({ length: 10 }).map((_, index) => ({
							id: (page - 1) * 10 + index + 1,
							title: `News Title ${index + 1}`,
							summary: `Summary of news ${index + 1}`,
							date: new Date().toISOString().split('T')[0],
							author: `Author ${index + 1}`,
							source: `Source ${index + 1}`,
							url: `/news/${(page - 1) * 10 + index + 1}`,
						}))
						console.log('模拟异步数据请完成,输出')
						resolve(data)
					}, 1000)
				})
			},
		},
		modules,
		mutations: {
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
			setLoading(state, status) {
				state.collocationLoading = status
			},
			setHost(state, host) {
				state.host = host
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setFirebase(state, firebaseConfig) {
				state.firebaseConfig = firebaseConfig
			},
		},
		getters: {
			getDeviceType: (state) => state.deviceType,
			showDebug: (state) => state.showDebug,
		},
	})
}
